/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import PageLayout from '../components/page-layout'
import { Link, graphql } from 'gatsby'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { Box, Flex, Text } from '@theme-ui/components'
import Image from 'gatsby-image'

const CarouselArrow = props => {
  const { theme } = useThemeUI()
  const { style, className, ...rest } = props
  return (
    <Box
      sx={{
        ...style,
        color: `${theme.colors.text}`,
        ':before': {
          color: `${theme.colors.text}`,
        },
      }}
      {...rest}
      className={className}
    ></Box>
  )
}

const testimonialStudents = [
  {
    key: 'audrey',
    name: 'Audrey Pray',
    testimonial: `My favorite thing about studying with Merly is that she takes a keen interest 
    in learning how I process information as an English-speaker. Her level of attention to details 
    about grammar, syntax, and pronunciation have shaped her into a professor with an in-depth 
    understanding of why I make the mistakes that I do. And thankfully, she also possesses the 
    ingenuity of games and exercises to stop me from making those mistakes and reach the level of 
    fluidity in Spanish that I aspire to have. Merly has the heart of a friend who will never let 
    you beat yourself up, nor allow you to be less than what you can become. I am grateful for how 
    much I have grown as a Spanish-speaker and overall student while working with her.`,
  },
  {
    key: 'jen',
    name: 'Jennifer Flaks',
    testimonial: `Andrea is one of the most generous people I know. She is a skilled and 
    experienced (and patient) teacher. She targets each lesson based on my needs and adjusts the 
    pace of learning based on my comprehension. I started knowing just a handful of Spanish 
    words and because of her, I am now an intermediate Spanish speaker on my way to fluency.`,
  },
  {
    key: 'ian',
    testimonial: `I was so lucky to have Merly teach me Spanish. Merly’s classes were very 
    challenging and informative, yet hilarious, and always left me feeling excited for the 
    next session. Merly is a knowledgeable, creative, and passionate Spanish teacher and I would 
    highly recommend Merly to anyone who is serious about learning Spanish and wants to have a 
    great time doing so!`,
    name: 'Ian Veronin',
  },
  {
    key: 'ryan',
    name: 'Ryan Oswald',
    testimonial: `Andrea is the best teacher I’ve ever had! She always keeps our lessons fun
    and interesting. She is smart, intuitive and student focused. She observes her students and 
    tailors the classes in a way that best fits your learning style, and at the same time 
    challenges you to make sure you are always progressing while keeping a smile on your face.`,
  },
  {
    key: 'tara',
    name: 'Tara Pahnke',
    testimonial: `Merly has been my Spanish teacher for 7 months and my fluency has exponentially 
    increased! After may years of struggling with personal study, I have finally broken through 
    on my areas of difficulty. She is patient, fun, and very knowledgeable. She always comes to our 
    session prepared and has a plan laid out for my improvement. Additionally, she uses numerous 
    teaching methods to help me understand and solidify concepts. She is relaxed, but also has very 
    objective, personalized goals for me. She challenges me to work hard at my studies, but also 
    wants me to enjoy the process. Merly has been an amazing asset to my pursuit of the Spanish language.  
    Thank you!!`,
  },
  {
    key: 'sumi',
    name: 'Sumi Mahendran',
    testimonial: `Andrea is a very effective teacher. She is very patient, encouraging and always 
    full of enthusiasm. I was able to speak quite fast with native Spanish speakers after a month 
    of intensive learning with her. You can ask any question, complicated or absurd, and she will 
    answer them explaining context and usage.`,
  },
]

const Testimonial = ({ image, text, name }) => {
  return (
    <Flex
      sx={{
        my: [2, 3, null],
        mx: [2, 3, null],
        flexDirection: ['column', 'row', null],
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: ['50%', '25%', null],
          mr: [0, 4, null],
          mb: [2, 0, null],
          minWidth: '25%',
          clipPath: 'circle(50% at center)',
        }}
      >
        <Image fluid={image.fluid} />
      </Box>
      <Box
        sx={{
          fontSize: [0, 2, 2],
        }}
      >
        <Text sx={{}}>{text}</Text>
        <Text sx={{ mt: 3 }}>-- {name}</Text>
      </Box>
    </Flex>
  )
}

const ClassesPage = ({ data }) => {
  return (
    <PageLayout
      title="Private classes"
      description="Book online private Spanish classes with our teachers Merly and Andrea"
    >
      <h1>Private classes</h1>
      <Box>
        <Box
          sx={{
            width: ['100%', '50%', null],
            float: 'right',
            ml: 3,
            mb: 3,
          }}
        >
          <Image fluid={data.andreaAndMerly.childImageSharp.fluid} />
        </Box>
        <p>
          Do you want to learn Spanish from scratch, or are you currently learning and want to take
          your Spanish to the next level? There's no faster way to improve than taking private
          classes, and our teachers would be delighted to help you reach your Spanish goals.
        </p>
        <p>
          Our classes focus on Latin American Spanish, with an emphasis on conversation. We use
          every grammatical theme that we teach in actual conversation, providing realistic context
          to help you reach fluency as efficiently and practically as possible.
        </p>
        <p>
          The first session is <strong>100% free</strong>. In this first 40 minute session, we will
          evaluate your level, outline a plan to help you reach your Spanish language goals, and
          explain our teaching methodology.
        </p>
        <p>
          So what are you waiting for? Get started now with a{' '}
          <strong>free 40 minute session</strong> by writing to us at{' '}
          <a sx={{ textDecoration: 'none' }} href="mailto:classes@getfluentspanish.com">
            classes@getfluentspanish.com
          </a>
          .
        </p>
      </Box>
      <h2 sx={{ mt: 4 }}>About our teachers</h2>
      <p>
        Merly and Andrea are Colombian professional Spanish teachers, both with university language
        degrees and many years of experience teaching Spanish as a foreign language. For more
        information about our team, check out our{' '}
        <Link style={{ textDecoration: 'none' }} to="/about">
          about us page
        </Link>
        .
      </p>
      <h2 sx={{ mt: 4 }}>Testimonials</h2>
      <Box
        sx={{
          mx: 3,
          '.slick-initialized .slick-track': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <Slider
          dots={true}
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<CarouselArrow />}
          prevArrow={<CarouselArrow />}
          arrows={true}
        >
          {testimonialStudents.map(student => {
            return (
              <Testimonial
                key={student}
                image={data[student.key].childImageSharp}
                text={student.testimonial}
                name={student.name}
              />
            )
          })}
        </Slider>
      </Box>
      <h2 sx={{ mt: 4 }}>Frequently asked questions</h2>
      <h3>Which teacher will I have classes with?</h3>
      <p>
        Assignment of students to a teacher will depend on the availability of Merly and Andrea at
        the time. Both are highly experienced, so don't worry, you'll be in good hands no matter who
        you're paired with!
      </p>
      <h3>What level of Spanish do I need to take classes with you?</h3>
      <p>
        We accept students of all levels! We have experience teaching people starting from zero all
        the way up to advanced learners looking to polish their Spanish to a C1 or C2 level. We will
        work together to construct a plan designed to reach your specific goals, no matter where
        you're starting from.
      </p>
      <h3>How do the classes work?</h3>
      <p>
        Our classes take place via internet video call. We use the application{' '}
        <a sx={{ textDecoration: 'none' }} href="https://zoom.us/">
          Zoom
        </a>
        , which can be downloaded for free. Classes typically last from 1-2 hours, depending on the
        student's preference.
      </p>
      <h3>How do I pay for my classes?</h3>
      <p>
        We accept payment via online money transfer, the details of which will be sent to you after
        the first free 40 minute session. All classes must be paid in advance.
      </p>
    </PageLayout>
  )
}

export const testimonialImage = graphql`
  fragment testimonialImage on File {
    childImageSharp {
      fluid(maxWidth: 480) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export const pagequery = graphql`
  query {
    audrey: file(relativePath: { eq: "testimonials/audrey.png" }) {
      ...testimonialImage
    }
    tara: file(relativePath: { eq: "testimonials/tara.png" }) {
      ...testimonialImage
    }
    ian: file(relativePath: { eq: "testimonials/ian.png" }) {
      ...testimonialImage
    }
    ryan: file(relativePath: { eq: "testimonials/ryan.png" }) {
      ...testimonialImage
    }
    sumi: file(relativePath: { eq: "testimonials/sumi.png" }) {
      ...testimonialImage
    }
    jen: file(relativePath: { eq: "testimonials/jen.png" }) {
      ...testimonialImage
    }
    andreaAndMerly: file(relativePath: { eq: "andrea_and_merly.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ClassesPage
