/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Text, Flex } from '@theme-ui/components'

import { IconContext } from 'react-icons'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'

const shareButtonSize = '24px'

const Footer = () => {
  const text = 'Copyright © 2020 Get Fluent Spanish'
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'row',
        mt: 5,
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'grey',
        fontSize: 1,
      }}
    >
      <Text
        sx={{
          color: 'grey',
          mr: 4,
        }}
      >
        {text}
      </Text>

      <IconContext.Provider value={{ size: shareButtonSize, color: 'grey' }}>
        <Flex
          sx={{
            justifyContent: 'center',
            '& > :not(:last-child)': { mr: 2 },
            a: { height: shareButtonSize },
          }}
        >
          <a href="https://www.facebook.com/getfluentspanish/">
            <FaFacebookSquare />
          </a>
          <a href="https://www.instagram.com/getfluentspanish/">
            <FaInstagram />
          </a>
        </Flex>
      </IconContext.Provider>
    </Flex>
  )
}

export default Footer
